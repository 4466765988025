import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useContext } from "react"
import * as R from "ramda"
import PropTypes from "prop-types"
import { FirebaseContext } from "../../firebase"
import { useMicroCopySv } from "../../hooks/microCopy/sv"
import { useMicroCopyEn } from "../../hooks/microCopy/en"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import { BgImage } from "gbimage-bridge"
import Logo from "../../images/logo_footer 1.svg"
import LogoSv from "../../images/logo_sv_white.svg"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import * as styles from "./footer.module.scss"


const Footer = ({language}) => {
  // UI texts
  const microCopyTexts = language === 'sv' ? useMicroCopySv : language === 'en' ? useMicroCopyEn : useMicroCopyFi
  const labelNewsletterTitle = GetMicroCopy(
    microCopyTexts,
    "uutiskirjePopUpOtsikko"
  )
  const labelNewsletterText = GetMicroCopy(
    microCopyTexts,
    "uutiskirjePopUpTeksti"
  )
  const labelNewsletterCta = GetMicroCopy(microCopyTexts, "uutiskirjeTilaa")
  const labelCookieSettings = GetMicroCopy(microCopyTexts, "evästeAsetukset")
  // end UI texts
  const { user } = useContext(FirebaseContext)

  const footerQuery = useStaticQuery(graphql`
    query footerQuery {
      primary: contentfulNavigaatio(name: { eq: "Alanavigaatio" }, node_locale: { eq: "fi" }) {
        name
        links {
          id
          key
          value
        }
      }
      secondary: contentfulNavigaatio(name: { eq: "Apunavigaatio" }, node_locale: { eq: "fi" }) {
        name
        links {
          id
          key
          value
        }
      }
      primarySv: contentfulNavigaatio(name: { eq: "Alanavigaatio" }, node_locale: { eq: "sv" }) {
        name
        links {
          id
          key
          value
        }
      }
      secondarySv: contentfulNavigaatio(name: { eq: "Apunavigaatio" }, node_locale: { eq: "sv" }) {
        name
        links {
          id
          key
          value
        }
      }
      primaryEn: contentfulNavigaatio(name: { eq: "Alanavigaatio" }, node_locale: { eq: "en" }) {
        name
        links {
          id
          key
          value
        }
      }
      secondaryEn: contentfulNavigaatio(name: { eq: "Apunavigaatio" }, node_locale: { eq: "en" }) {
        name
        links {
          id
          key
          value
        }
      }
      siteDetailsFi: allContentfulSiteDetail(filter: {node_locale: { eq: "fi" }}) {
        edges {
          node {
            value
            spacer
            order
          }
        }
      }
      siteDetailsSv: allContentfulSiteDetail(filter: {node_locale: { eq: "sv" }}) {
        edges {
          node {
            value
            spacer
            order
          }
        }
      }
      siteDetailsEn: allContentfulSiteDetail(filter: {node_locale: { eq: "en" }}) {
        edges {
          node {
            value
            spacer
            order
          }
        }
      }
      allContentfulIcon: allContentfulIcon(filter: {node_locale: { eq: "fi" }}) {
        nodes {
          href
          file {
            file {
              url
            }
          }
        }
      }
      vector: file(relativePath: { eq: "shape_footer_corner.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `)
  const siteDetails = language === 'sv' ? footerQuery.siteDetailsSv.edges : language === 'en' ? footerQuery.siteDetailsEn.edges : footerQuery.siteDetailsFi.edges;
  const navItems = language === 'sv' ? footerQuery.primarySv.links : language === 'en' ? footerQuery.primaryEn.links : footerQuery.primary.links
  const subNavItems = language === 'sv' ? footerQuery.secondarySv.links : language === 'en' ? footerQuery.secondaryEn.links : footerQuery.secondary.links
  const vectorImage = R.path(
    ["childImageSharp", "gatsbyImageData"],
    footerQuery.vector
  )

  function handleCookieOverlay() {
    window.popup.renderScreen("change_settings")
  }

  const renderSiteDetails = () => {
    const sortedSiteDetails = siteDetails
      .filter(({ node }) => node.value !== null) // Skip the empty/null details
      .sort((a, b) => a.node.order - b.node.order);
    return sortedSiteDetails.map(({ node }, index) => {
      let separator;
  
      switch (node.spacer) {
        case 'br':
          separator = <br />;
          break;
        case 'space':
          separator = <> <br /> <br /> </>;
          break;
        default:
          separator = null;
      }
  
      return (
        <React.Fragment key={index}>
          <text style={{ fontSize: "15px" }}>
            {node.value}
            {separator}
          </text>
        </React.Fragment>
      );
    });
  }

  return (
    <footer
      style={{
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", bottom: "0px", right: "0px" }}>
        <BgImage
          style={{ marginBottom: "0px", width: "278px", height: "176px" }}
          image={vectorImage}
        />
      </div>

      {language === 'sv' ? (
  <div className={styles.footerContainerSv}>
    <Grid
      container
      style={{ maxWidth: "1200px", justifyContent: "center"}}
      spacing={2} // Adjust spacing as needed
      direction="row"
    >
      {/* Site Details and Logo */}
      <Grid
        item
        xs={12} sm={6} lg={4} // Adjust grid sizing as needed for responsive layout
        className={styles.address}
        style={{ textAlign: "center", padding: "0 2%" }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            alt="Bostadsmässan"
            src={LogoSv}
            style={{
                width: "258px",
                height: "auto",
                margin: "0 auto 1.45rem auto",
            }}
          />
          {renderSiteDetails()}
        </div>
      </Grid>

      {/* Navigation Items */}
      {navItems && (
        <Grid
          item
          xs={12} sm={6} lg={8} // Adjust grid sizing as needed for responsive layout
          className={styles.navi}
          style={{ textAlign: "center", padding: "0 2%" }}
        >
          <nav className={styles.nav}>
            {navItems.map(item => (
              <div
                className={styles.linkContainer}
                key={R.path(["key"], item)}
              >
                {R.path(["value"], item) && (
                  <Link
                    to={`${R.path(["value"], item)}`}
                    key={`${R.path(["id"], item)}`}
                    partiallyActive={true}
                    activeClassName={styles.active}
                    className="footer-black"
                    activeStyle={{ borderBottomColor: "#69d2fa" }}
                  >
                    {R.path(["key"], item)}
                  </Link>
                )}
              </div>
            ))}
          </nav>
        </Grid>
      )}
    </Grid>
  </div>
      ) : (
      <div className={styles.footerContainer}>
        <Grid
          container
          style={{ maxWidth: "1200px" }}
          spacing={0}
          direction="row"
        >
          <Grid
            item
            xs={12}
            lg={4}
            xl={4}
            className={styles.address}
            style={{ textAlign: "center", padding: "0 2%" }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
                <img
                  alt="Asuntomessut"
                  src={Logo}
                  style={{
                  width: "258px",
                  height: "164px",
                  margin: "0 auto 1.45rem auto",
                }}
                />
              {renderSiteDetails()}
              <Grid container spacing={0} direction="row" style={{ padding: "0 40px" }}>
              {footerQuery?.allContentfulIcon?.nodes.map((node, index) => (
                <Grid item xs key={index}>
                  <a target="_blank" rel="noreferrer" href={node.href}>
                    <img src={node?.file?.file?.url} />
                  </a>
                </Grid>
              ))}
            </Grid>
            </div>
          </Grid>

          <Grid item xs={12} lg={3} xl={3} className={styles.navi}>
            {navItems && (
              <nav className={styles.nav}>
                {navItems.map(item => (
                  <div
                    className={styles.linkContainer}
                    key={R.path(["key"], item)}
                  >
                    {R.path(["value"], item) && (
                      <Link
                        to={`${R.path(["value"], item)}`}
                        key={`${R.path(["id"], item)}`}
                        partiallyActive={true}
                        activeClassName={styles.active}
                        className="footer-black"
                        activeStyle={{ borderBottomColor: "#69d2fa" }}
                      >
                        {R.path(["key"], item)}
                      </Link>
                    )}
                  </div>
                ))}
              </nav>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={5}
            xl={5}
            style={{
              textAlign: "center",
              padding: "0 2%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className={`${styles.mailList}`}
          >
            <h2 className="footer">{labelNewsletterTitle}</h2>
            <p>{labelNewsletterText}</p>
            <div className={styles.mailListInner}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://asuntomessut.us6.list-manage.com/subscribe?u=baad3ce0a65ba479b91b56095&id=8f21b6c3f3"
                className="yellow"
              >
                <Button variant="contained" color="primary">
                  {labelNewsletterCta}
                </Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
      )}

      <div
        style={{
          backgroundColor: "#fff",
          textAlign: "center",
          height: "116px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.subnavcontainer}>
          {subNavItems && (
            <nav className={styles.subnav}>
              {subNavItems.map(item => (
                <div
                  className={styles.linkContainer}
                  key={`${R.path(["value"], item)}`}
                >
                  <Link
                    to={`${R.path(["value"], item)}`}
                    key={`${R.path(["id"], item)}`}
                    partiallyActive={true}
                    activeClassName={styles.active}
                    activeStyle={{ borderBottomColor: "#69d2fa" }}
                  >
                    {R.path(["key"], item)}
                  </Link>
                </div>
              ))}
              <button
                className="p-0 !text-black bg-transparent border-solid border-b-2 border-t-0 border-x-0 border-transparent leading-none hover:!border-[#ccc] hover:!text-black cursor-pointer"
                onClick={handleCookieOverlay}
                type="button"
              >
                {labelCookieSettings}
              </button>
            </nav>
          )}

          {language === 'fi' ? (
          <p style={{ marginTop: "10px", marginBottom: "0px" }}>
            @ Osuuskunta Suomen Asuntomessut
          </p>
          ) : (
            <p style={{ marginTop: "10px", marginBottom: "0px" }}>
            @ Andelslaget Finlands Bostadsmässa
          </p>
          )}

        </div>
      </div>
    </footer>
  )
}

export default Footer
