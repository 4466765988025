// extracted by mini-css-extract-plugin
export var active = "footer-module--active--YnBrS";
export var address = "footer-module--address--tEixc";
export var footerContainer = "footer-module--footerContainer--cqI4v";
export var footerContainerSv = "footer-module--footerContainerSv--CvWC4";
export var linkContainer = "footer-module--linkContainer--pA21R";
export var mailList = "footer-module--mailList--aVqKl";
export var mailListInner = "footer-module--mailListInner--UD-nL";
export var nav = "footer-module--nav--1Mii1";
export var navi = "footer-module--navi--NjoPH";
export var subnav = "footer-module--subnav--yuawk";
export var subnavcontainer = "footer-module--subnavcontainer--sHDJV";