import React from "react"
import Layout from "../layout"
import PropTypes from "prop-types"

const LayoutController = ({ children, language }) => {

  return <Layout children={children} language={language} />
}

LayoutController.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LayoutController