import { useStaticQuery, graphql } from "gatsby"

export const useMicroCopyFi = () => {
  const { allContentfulMikroTeksti } = useStaticQuery(
    graphql`
      query microCopyFi {
        allContentfulMikroTeksti (filter: { node_locale: { eq: "fi" } }) {
          edges {
            node {
              key
              value {
                value
              }
            }
          }
        }
      }
    `
  )
  return allContentfulMikroTeksti.edges
}
