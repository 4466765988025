import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 700,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default theme
