import React, { Component } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Hidden from "@material-ui/core/Hidden"

import * as styles from "./dialogNewsletter.module.scss"

export class DialogNewsletter extends Component {
  state = {
    dialogOpen: false,
  }

  setOpen(openState) {
    this.setState({ dialogOpen: openState })
  }

  componentDidMount() {
    let ampopup_seen = sessionStorage.getItem("ampopup_seen")
    this.timeout = setTimeout(() => {
      if (!ampopup_seen) {
        this.setOpen(true)
      }
    }, 5000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleClose = () => {
    this.setOpen(false)
    this.handleCookie()
  }

  handleCookie = () => {
    sessionStorage.setItem("ampopup_seen", "true")
  }

  render() {
    const { dialogOpen } = this.state

    return (
      <StaticQuery
        query={graphql`
          query newsletterQuery {
            greenmirror: file(relativePath: { eq: "greenmirror.png" }) {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
          }
        `}
        render={data => (
          <Dialog
            aria-labelledby="#dialog-title"
            open={dialogOpen}
            scroll="paper"
            maxWidth="lg"
            classes={{
              paper: styles.root,
            }}
          >
            <DialogContent className={styles.dialogcontent}>
              <div className={styles.cont}>
                <div style={{ textAlign: "right" }}>
                  <IconButton
                    aria-label={this.props.closeLabel}
                    className={styles.btn}
                    onClick={this.handleClose}
                  >
                    <CloseIcon style={{ color: "white" }} />
                  </IconButton>
                </div>

                <h2
                  id="dialog-title"
                  className={styles.headline}
                  style={{ fontSize: "50px" }}
                >
                  {this.props.title}
                </h2>
                <p>{this.props.text}</p>
                <Link
                  to={`https://asuntomessut.us6.list-manage.com/subscribe?u=baad3ce0a65ba479b91b56095&id=8f21b6c3f3`}
                  target="_blank"
                  className="yellow"
                  onClick={this.handleCookie}
                >
                  <Button
                    style={{ marginBottom: "-60px" }}
                    variant="contained"
                    color="primary"
                  >
                    {this.props.cta}
                  </Button>
                </Link>
                <Hidden mdDown>
                  <div
                    style={{
                      position: "absolute",
                      width: "272px",
                      height: "184px",
                      left: "0px",
                      bottom: "0px",
                    }}
                  >
                    <GatsbyImage
                      alt=""
                      image={R.path(
                        ["greenmirror", "childImageSharp", "gatsbyImageData"],
                        data
                      )}
                      style={{ height: "100%" }}
                    />
                  </div>
                </Hidden>
                <Hidden lgUp smDown>
                  <div
                    style={{
                      position: "absolute",
                      width: "136px",
                      height: "92px",
                      left: "20px",
                      bottom: "-20px",
                    }}
                  >
                    <GatsbyImage
                      alt=""
                      image={R.path(
                        ["greenmirror", "childImageSharp", "gatsbyImageData"],
                        data
                      )}
                      style={{ height: "100%" }}
                    />
                  </div>
                </Hidden>
              </div>
            </DialogContent>
          </Dialog>
        )}
      />
    )
  }
}

export default DialogNewsletter
